import React from "react";
import { Article } from "../modules/Article";
import { Project } from "../modules/Project";
import { Router as MagykRouter } from "@reach/router";
import { JobPosting } from "../modules/JobPosting";

const Router = () => {
  return (
    <div>
       <MagykRouter>
      {/* TODO investigate wildcards */}
      {/* <Article path="/app/articles/:articleId" /> */}
      <Project path="/app/projects/:projectId" />
      <JobPosting path="/app/careers/:postingId" />
    </MagykRouter>
    </div>
  );
};


export default Router;
