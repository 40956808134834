import * as React from "react"
import SingleProjectComponent from "@components/Projects/ProjectId"
import Layout from "@components/layout";


export const Project  = ({ projectId = 1 }) => {

  return (
    <Layout>
        <SingleProjectComponent projectId={projectId} />
    </Layout>
  )
}