import React from "react";
import Layout from "@components/layout";
import JobPostingComponent from "@components/JobPosting";


export const JobPosting  = ({ postingId }) => {

  return (
    <Layout>
        <JobPostingComponent  postingId={postingId} />
    </Layout>
  )
}